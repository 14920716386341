/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

body.client {
    background: black;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

#preload {
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.client-view {

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    font-size: 0px;

}

.client-view-content {

    /* IE10 */
    display: -ms-flexbox;
    -ms-flex-align: stretch;
    -ms-flex-direction: column;
    -ms-flex-pack: end;

    /* Ancient Mozilla */
    display: -moz-box;
    -moz-box-align: stretch;
    -moz-box-orient: vertical;
    -moz-box-pack: end;
    
    /* Ancient WebKit */
    display: -webkit-box;
    -webkit-box-align: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-pack: end;

    /* Old WebKit */
    display: -webkit-flex;
    -webkit-align-items: stretch;
    -webkit-flex-direction: column;
    -webkit-flex-pack: end;

    /* W3C */
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-pack: end;

    width: 100%;
    height: 100%;

    font-size: 12pt;

}

.client-view .client-body {
    -ms-flex: 1 1 auto;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative;
}

.client-view .client-bottom {
    -ms-flex: 0 0 auto;
    -moz-box-flex: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
}

.client-view .client-body .main {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    width: auto;
    height: auto;

}

.client .menu .header h2 {
    text-transform: none;
}

.client .user-menu .menu-contents li a.disconnect {
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: 0.75em center;
    padding-left: 2.5em;
    background-image: url('images/x.png');
}
