/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.transfer-manager {
    background: white;
}

.transfer-manager .header h2 {
    font-size: 1em;
    padding-top: 0;
    padding-bottom: 0;
}

.transfer-manager .header {
    margin: 0;
    -ms-flex-align:      center;
    -moz-box-align:      center;
    -webkit-box-align:   center;
    -webkit-align-items: center;
    align-items:         center;
}

.transfer-manager .transfers {
    display: table;
    padding: 0.25em;
    width: 100%;
}
