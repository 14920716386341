/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

table.sorted {
    border-collapse: collapse;
}

table.sorted th {
    background: rgba(0, 0, 0, 0.125);
    font-weight: normal;
}

table.sorted th,
table.sorted td {
    border: 1px solid #AAA;
    padding: 0.5em 1em;
}

table.sorted th.sortable {
    cursor: pointer;
}

table.sorted th.sort-primary {
    font-weight: bold;
    padding-right: 0;
}

table.sorted th.sort-primary:after {

    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    content: ' ';

    background-size: 1em 1em;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url('images/arrows/down.png');

}

table.sorted th.sort-primary.sort-descending:after {
    background-image: url('images/arrows/up.png');
}
