/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.manage-user-group .page-tabs .page-list li.read-only a[href],
.manage-user-group .page-tabs .page-list li.unlinked  a[href],
.manage-user-group .page-tabs .page-list li.linked    a[href] {
    padding-right: 2.5em;
    position: relative;
}

.manage-user-group .page-tabs .page-list li.read-only a[href]:before,
.manage-user-group .page-tabs .page-list li.unlinked  a[href]:before,
.manage-user-group .page-tabs .page-list li.linked    a[href]:before {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 2.5em;
    background-size: 1.25em;
    background-repeat: no-repeat;
    background-position: center;
}

.manage-user-group .page-tabs .page-list li.read-only a[href]:before {
    background-image: url('images/lock.png');
}

.manage-user-group .page-tabs .page-list li.unlinked a[href]:before {
    background-image: url('images/plus.png');
}

.manage-user-group .page-tabs .page-list li.unlinked a[href] {
    opacity: 0.5;
}

.manage-user-group .page-tabs .page-list li.unlinked a[href]:hover,
.manage-user-group .page-tabs .page-list li.unlinked a[href].current {
    opacity: 1;
}

.manage-user-group .page-tabs .page-list li.linked a[href]:before {
    background-image: url('images/checkmark.png');
}

.manage-user-group .notice.read-only {

    background: #FDA;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25em;

    text-align: center;
    padding: 1em;

}